/* purgecss start ignore */

.errorPhone{
  padding-left:0
}

@media(min-width: 1024px){
  .errorPhone{
    padding-left:136px
  }
}

.sendOTPErrorModal{
  width:calc(100vw - 24px);
  max-width:375px;
  display:flex;
  flex-direction:column;
  border-radius:8px;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  padding:16px
}

@media (min-width: 1024px){
  .sendOTPErrorModal{
    padding:24px
  }
}

@media(min-width: 1024px){
  .sendOTPErrorModal{
    width:436px;
    max-width:436px
  }
}

/* purgecss end ignore */