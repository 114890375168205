/* purgecss start ignore */

.container{
  --tw-bg-opacity:1;
  background-color:rgba(36, 89, 173, var(--tw-bg-opacity));
  padding-left:12px;
  padding-right:12px;
  padding-bottom:0.25rem
}

.titleFooter{
  color:#fff;
  font-family:Mulish, sans-serif;
  font-size:13px;
  font-weight:700
}

@media (min-width: 1024px){
  .titleFooter{
    font-size:15px
  }
}

.subTitleFooter{
  color:#fff;
  font-family:Mulish, sans-serif;
  font-size:11px;
  line-height:13.81px
}

@media (min-width: 1280px){
  .subTitleFooter{
    font-size:13px
  }
}

.textFooter{
  color:#fff;
  font-family:Mulish, sans-serif;
  font-family:Mulish, sans-serif;
  font-size:12px
}

@media (min-width: 1536px){
  .textFooter{
    font-size:13px
  }
}

.buttonDownLoad{
  display:flex;
  height:32px;
  width:120px;
  cursor:pointer;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  border-radius:4px;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

/* purgecss end ignore */