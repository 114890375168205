@import "~@styles/variables.scss";
.headerContainer {
  z-index: 1000;
  padding: 0 16px 0 24px;
  @apply flex flex-row w-full items-center justify-between bg-white h-49px lg:h-64px
}


@media (min-width: 1024px) {
  .headerContainer {
    padding: 0 24px;
    @apply justify-start bg-blue-main
  }
}

.titleMenu {
  @apply text-13 xl:text-15 text-white whitespace-nowrap mr-6px font-bold
}

.innerHeaderRightContent {
  @apply flex-row hidden lg:flex items-center overflow-x-auto overflow-y-hidden flex-1
}


.innerHeaderRightContent::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0);
  border-radius:16px;
  border:5px solid #fff;
}

.innerHeaderRightContent:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
}

.innerHeaderRightContent::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

.iconArrowHeader {
  fill: #fff;
  @apply w-16px h-16px
}

.activeArrowHeader {
  transform: rotate(180deg);
}

.containerSelector {
  min-width: 300px;
  @apply bg-white rounded-rd4px py-8px flex flex-col border border-blue-main border-solid
}

.line {
  border-top: 1px solid #e5e5e5e5;
}

.itemSelector {
  @apply px-12px cursor-pointer h-32px justify-center flex flex-col
}

.itemSelector:hover {
  background-color: #2459ad20;
  @apply font-bold text-blue-main
}

.activeItemSelector {
  @apply font-bold text-blue-main
}

.btnNextWork {
  min-width: 128px;
  @apply bg-white rounded-rd16px flex flex-col h-32px items-center justify-center mr-16px
}
.btnPost {
  background-color: #0D4296;
  border: 1px solid #90B8F8;
  min-width: 128px;
  @apply rounded-rd16px h-32px items-center justify-center text-white flex flex-row justify-between px-24px
}

.whiteText {
  @apply text-white whitespace-nowrap
}

.verticalLine {
  @apply w-1px h-22px bg-white
}

.rowHideMore {
  @apply flex
}

.itemMore {
  @apply hidden mr-16px
}

@media (max-width: 1366px) {
  .rowHideMore {
    @apply hidden
  }
  .itemMore {
    @apply flex
  }
}

.notification {
  @apply flex lg:hidden items-center
}



