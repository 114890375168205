@import "~@styles/variables.scss";
.dropdownContainer {
  border-radius: 9px;
  max-height: 250px;
  min-height: 100px;
  box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.12);
  min-width: 250px;
  @apply border border-gray-300 bg-white overflow-visible overflow-scroll;
}

.selectItem {
  @apply flex w-full h-44px px-2.5 text-sm items-center cursor-pointer items-center justify-center;
}
.selectItemRadio {
  @apply w-full h-30px my-2.5 cursor-pointer;
}
.selectItem:hover {
  background-color: #2459ad;
  color: white !important;
}
