/* purgecss start ignore */

.rightBanner{
  background:linear-gradient(180deg, #E9F3FF 0%, #FFFFFF 100%);
  position:relative;
  display:flex;
  height:100%;
  width:277px;
  flex-direction:column
}

.containerModal{
  display:flex;
  overflow:hidden;
  border-radius:8px;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.uploadImgContainer{
  border:1px dashed #2459ad;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  border-radius:8px;
  padding-left:12px;
  padding-right:12px;
  padding-top:16px;
  padding-bottom:16px
}

.uploadImgContainerError{
  border:1px dashed #f32323
}

.uploadButton{
  border:1px dashed #2459ad;
  padding:4px 8px;
  margin-top:16px;
  display:flex;
  cursor:pointer;
  align-items:center;
  justify-content:center;
  align-self:center;
  border-radius:16px
}

.imgContainer{
  background-color:#fafafa;
  margin-top:16px;
  display:flex;
  width:100%;
  flex-wrap:wrap;
  border-radius:4px;
  padding:12px
}

.imgItem{
  border:1px solid #dedede;
  position:relative;
  display:flex;
  height:104px;
  width:104px;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  border-radius:8px
}

.img{
  width:104px !important;
  height:104px !important
}

.successModal{
  width:calc(100vw - 24px);
  max-width:375px;
  display:flex;
  flex-direction:column;
  border-radius:8px;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  padding:16px
}

@media (min-width: 1024px){
  .successModal{
    padding:24px
  }
}

@media(min-width: 1024px){
  .successModal{
    min-width:436px;
    width:436px
  }
}

.image{
  width:104px !important;
  height:104px !important;
  position:relative;
  border-radius:8px;
  border-width:1px;
  --tw-border-opacity:1;
  border-color:rgba(107, 114, 128, var(--tw-border-opacity));
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat
}

.cityButton{
  padding:0 1px !important
}

/* purgecss end ignore */