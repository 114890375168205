@import "~@styles/variables.scss";
.leftBanner {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: linear-gradient(180deg, #E9F3FF 0%, #FFFFFF 100%);
  @apply w-277px h-full flex flex-col
}

.modalContainer {
  height: 643px;
 @apply flex flex-row w-840px rounded-rd4px overflow-hidden
}

.innerLefBanner {
  @apply flex flex-1 flex-col px-24px pt-24px
}

.switchContainer {
  padding-left: 40px;
  min-height: 40px;
  @apply flex flex-row w-full h-40px border-b border-blue-main
}

.titleSwitch {
  @apply text-15 text-black font-text
}

.titleActive {
  @apply text-blue-main font-bold font-textBold
}

.horizontalBar {
  bottom: -4px;
  transform: translate(30%, -30%);
  @apply absolute
}

.bottomNote {
  border-bottom-right-radius: 8px;
  min-height: 40px;
  @apply flex flex-row w-full bg-gray-100 items-center justify-center space-x-1
}
