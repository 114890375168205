@import "~@styles/variables.scss";
.container {
  max-width: 100%;
  overflow-x: hidden;
  @apply w-full flex flex-col items-center;
}

.innerContainer {
  max-width: $page-max-width;
  @apply w-full flex flex-col;
}
