@import "~@styles/variables.scss";
.rightBanner {
  background: linear-gradient(180deg, #E9F3FF 0%, #FFFFFF 100%);
  @apply h-full w-277px flex flex-col relative
}

.containerModal {
  @apply flex bg-white rounded-rd8px overflow-hidden
}

.uploadImgContainer {
  border: 1px dashed #2459ad;
  @apply flex flex-col rounded-rd8px px-12px py-16px items-start
}

.uploadImgContainerError {
  border: 1px dashed #f32323;
}

.uploadButton {
  border: 1px dashed #2459ad;
  padding: 4px 8px;
  @apply flex rounded-rd16px cursor-pointer items-center justify-center mt-16px self-center
}

.imgContainer {
  background-color: #fafafa;
  @apply flex flex-wrap rounded-rd4px mt-16px p-12px w-full
}

.imgItem {
  border: 1px solid #dedede;
  @apply rounded-rd8px w-104px h-104px items-center justify-center flex-col flex relative
}

.img {
  width: 104px !important;
  height: 104px !important;
}

.successModal {
  width: calc(100vw - 24px);
  max-width: 375px;
  @apply bg-white rounded-rd8px lg:p-24px p-16px flex flex-col
}

@media (min-width: 1024px) {
  .successModal {
    min-width: 436px;
    @apply w-436px
  }
}

.image {
  width: 104px !important;
  height: 104px !important;
  @apply bg-no-repeat bg-center bg-cover border border-gray-500 rounded-rd8px relative
}

.cityButton {
  padding: 0 1px !important;
}
