/* purgecss start ignore */

.headerContainer{
  z-index:1000;
  padding:0 16px 0 24px;
  display:flex;
  height:49px;
  width:100%;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

@media (min-width: 1024px){
  .headerContainer{
    height:64px
  }
}

@media(min-width: 1024px){
  .headerContainer{
    padding:0 24px;
    justify-content:flex-start;
    --tw-bg-opacity:1;
    background-color:rgba(36, 89, 173, var(--tw-bg-opacity))
  }
}

.titleMenu{
  margin-right:6px;
  white-space:nowrap;
  font-size:13px;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

@media (min-width: 1280px){
  .titleMenu{
    font-size:15px
  }
}

.innerHeaderRightContent{
  display:none;
  flex:1 1 0%;
  flex-direction:row;
  align-items:center;
  overflow-x:auto;
  overflow-y:hidden
}

@media (min-width: 1024px){
  .innerHeaderRightContent{
    display:flex
  }
}

.innerHeaderRightContent::-webkit-scrollbar-thumb{
  background-color:rgba(0,0,0,0);
  border-radius:16px;
  border:5px solid #fff
}

.innerHeaderRightContent:hover::-webkit-scrollbar-thumb{
  background-color:#a0a0a5
}

.innerHeaderRightContent::-webkit-scrollbar-thumb:hover{
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

.iconArrowHeader{
  fill:#fff;
  height:16px;
  width:16px
}

.activeArrowHeader{
  transform:rotate(180deg)
}

.containerSelector{
  min-width:300px;
  display:flex;
  flex-direction:column;
  border-radius:4px;
  border-width:1px;
  border-style:solid;
  --tw-border-opacity:1;
  border-color:rgba(36, 89, 173, var(--tw-border-opacity));
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top:8px;
  padding-bottom:8px
}

.line{
  border-top:1px solid #e5e5e5e5
}

.itemSelector{
  display:flex;
  height:32px;
  cursor:pointer;
  flex-direction:column;
  justify-content:center;
  padding-left:12px;
  padding-right:12px
}

.itemSelector:hover{
  background-color:#2459ad20;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(36, 89, 173, var(--tw-text-opacity))
}

.activeItemSelector{
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(36, 89, 173, var(--tw-text-opacity))
}

.btnNextWork{
  min-width:128px;
  margin-right:16px;
  display:flex;
  height:32px;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  border-radius:16px;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.btnPost{
  background-color:#0d4296;
  border:1px solid #90b8f8;
  min-width:128px;
  display:flex;
  height:32px;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  justify-content:space-between;
  border-radius:16px;
  padding-left:24px;
  padding-right:24px;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.whiteText{
  white-space:nowrap;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.verticalLine{
  height:22px;
  width:1px;
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.rowHideMore{
  display:flex
}

.itemMore{
  margin-right:16px;
  display:none
}

@media(max-width: 1366px){
  .rowHideMore{
    display:none
  }

  .itemMore{
    display:flex
  }
}

.notification{
  display:flex;
  align-items:center
}

@media (min-width: 1024px){
  .notification{
    display:none
  }
}

/* purgecss end ignore */