@import "~@styles/variables.scss";
.container {
  @apply pb-1 bg-blue-main px-12px
}

.titleFooter {
  color: white;
  @apply font-text font-bold text-13 lg:text-15
}

.subTitleFooter {
  color: white;
  @apply text-11 font-text xl:text-13
}

.textFooter {
  color: white;
  @apply text-12 font-text font-text 2xl:text-13
}

.buttonDownLoad {
  @apply w-120px h-32px bg-white rounded-rd4px flex flex-row items-center justify-center cursor-pointer
}
