@import "~@styles/variables.scss";
.container {
  @apply bg-white rounded-rd8px p-12px border border-blue-main flex flex-col
}

.itemDate {
  @apply w-24px h-24px flex flex-col items-center justify-center rounded-rd50 font-text text-13
}

.calendarContainer {
  @apply flex flex-col border-dashed border border-blue-main rounded-rd8px p-8px
}

.today {
  @apply bg-red-600 text-white
}


.boxTime {
  @apply w-40px h-40px bg-gray-200 rounded-rd8px flex items-center justify-center text-14 font-textBold font-bold
}

.dateSelected {
  @apply bg-blue-main text-white
}

.buttonChangeMonth {
  @apply w-24px h-24px mr-4px rounded-rd50 border border-blue-main flex items-center justify-center
}
