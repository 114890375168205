@import "~@styles/variables.scss";
.radioButton {
  border: 2px solid #DEDEDE;
  border-radius: 50%;
  padding: 2px;
  @apply w-18px h-18px mr-4px
}

.checked {
  border-color: #2459ad;
}

.innerChecked {
  @apply w-full h-full rounded-rd50
}
