@import "~@styles/variables.scss";
.errorPhone {
  padding-left: 0;
}

@media (min-width: 1024px) {
  .errorPhone {
    padding-left: 136px;
  }
}

.sendOTPErrorModal {
  width: calc(100vw - 24px);
  max-width: 375px;
  @apply bg-white flex flex-col rounded-rd8px p-16px lg:p-24px
}

@media (min-width: 1024px) {
  .sendOTPErrorModal {
    width: 436px;
    max-width: 436px;
  }
}
