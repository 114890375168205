@import "~@styles/variables.scss";
.container {
  width: calc(100vw - 24px);
  max-width: 375px;
  @apply rounded-rd8px p-12px flex flex-col
}

@media (min-width: 1024px) {
  .container {
    max-width: 650px;
    @apply w-650px
  }
}
