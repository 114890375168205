@import "~@styles/variables.scss";

.label{
  @apply text-blue-main font-semibold text-13
}

.inputContainer {
  @apply rounded-rd8px flex-row flex items-center border border-blue-main bg-white
}

.inputFocus {
  border: 1px solid #2459ad;
}

.input {
  outline:none!important;
  border:none !important;
  @apply flex-1 text-13 font-text rounded-rd8px px-2 overflow-hidden h-40px
}

.inputError {
  @apply border-red-400;
}

.input:disabled {
  @apply bg-gray-100
}
