@import "~@styles/variables.scss";
.inputDeveloper {
  align-items: start !important;
  flex-direction: column !important;
  @apply w-full
}

.wrapperScanQrCode {
  width: 100% !important;
  max-width: 351px !important;
  padding: 12px !important;
}

.containerNotification {
  width: 100% !important;
  max-width: 351px !important;
  padding: 12px !important;
}

.innerCompanyField {
  display: flex !important;
  flex-direction: column !important;
  @apply mt-8px
}

.containerOTPCode {
  max-width: 351px !important;
  width: calc(100vw - 24px);
  @apply bg-white rounded-rd8px p-12px
}
