@import "~@styles/variables.scss";


.label{
  @apply text-blue-main font-semibold text-base
}
.inputContainer {
  border-color: #2459ad;
  height: 40px;
  @apply rounded-lg border border-solid pr-2
}
.innerInput{
  outline:none!important;
  @apply flex-1 text-sm rounded-lg placeholder-gray-400 px-2
}
.inputError {
  @apply border-red-400;
}
.inputHint{
  top:72px;
  @apply w-full absolute z-10 border border-gray-400 bg-white
}

